/* margins of 272px are there to prevent left menu bar from overlapping with content*/
/*  style guide */
#root {
  min-height: 100vh;
}

.app-container {
min-height: 100vh;
display: flex;
flex-direction: column;
justify-content:space-between
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  transition-duration: 0.5s;
  --secondary: #1F185B;
  --secondary-light: #76729A;
  --secondary-dark: #292641;

  --primary: #FF6A36;
  --primary-light: #FF9C79;
  --primary-dark: #AA4724;

  --text: #5c5c5c;
  --text-light: #ffffff;
  --text-dark: #303030;
  --text-button: #ffffff;
  --text-especializaciones: var(--secondary);

  --bg: #F9F9F9;
  --bg-dark: #f0f0f0;
  --bg-dark-services: #2a2742;
  --bg-step: #F9F9F9;
  --bg-login: rgba(255, 255, 255, 0.5);
  --bg-topbar: rgba(255, 255, 255, 0.5);
  --bg-testimonials: url("/src/static/images/testimonials_background.svg")
  /* new palette color  */
}

[data-theme="dark"] {
  transition-duration: 0.5s;
  --secondary: #FF6A36;
  --secondary-light: #76729A;
  --secondary-dark: #292641;

  --primary: #FF6A36;
  --primary-light: #FF9C79;
  --primary-dark: #AA4724;

  --text: #5c5c5c;
  --text-light: #303030;
  --text-dark: #ffffff;
  --text-button: #ffffff;
  --text-especializaciones: #ffffff;

  --bg: #2A2742;
  --bg-dark: #1D1A2A;
  --bg-dark-services: #2a2742;
  --bg-step: #F9F9F9;
  --bg-login: rgba(0, 0, 0, 0.5);
  --bg-topbar: rgba(0, 0, 0, 0.7);
  --bg-testimonials: url("/src/static/images/testimonials_background_dark.svg")
}

.primary {
  color: var(--primary);
}

.primary-light {
  color: var(--primary-light);
}

.primary-dark {
  color: var(--primary-dark);
}

.secondary {
  color: var(--secondary);
}

.secondary-light {
  color: var(--secondary-light);
}

.secondary-dark {
  color: var(--secondary-dark);
}

.text {
  color: var(--text);
}

.text-dark {
  color: var(--text-dark);
}

.text-light {
  color: var(--text-light);
}

.text-button {
  color: var(--text-button) !important;
}

.bg {
  color: var(--bg);
}

.bg {
  color: var(--bg-dark);
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300 !important;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.marginAuto {
  margin: auto;
}

#macarlupu-app,
body {
  transition-duration: 0.5s;
  color: var(--text-dark);
  background-color: var(--bg-dark);
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

.ds-light {
  font-family: "Montserrat", sans-serif;
  font-size: 68px;
  font-style: normal;
  font-weight: 400;
  line-height: 85px;
  letter-spacing: 0px;
  text-align: left;
}

li {
  margin-bottom: 10px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 57px;
  font-style: normal;
  font-weight: 300;
  line-height: 71px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primary);
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 59px;
  letter-spacing: 0px;
  text-align: left;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: left;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--secondary);
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}

.h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18.75px !important;
  color: var(--text-dark);
}

p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18.75px;
  color: var(--text-dark);
}

.lead {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.lead-bold {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: 700;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.base {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.base-bold {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.medium {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.medium-bold {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.small {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
}

.small-bold {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
}

.label {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}

.body2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
}

button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.subtitle2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: var(--text-dark);
}

.button-medium {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: var(--text-dark);
}

.button-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: var(--secondary);
}

.caption,
a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--text-dark);
}

.overline {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

ul {
  color: var(--text-dark);
}

.text-center {
  text-align: center!important;
}

.filters {
  background-color: var(--bg);
  border-radius: 10px;
}

.error {
  padding: 0;
  margin: 0;
  margin-top: 5px;
  color: red;
  font-size: 12px !important;
}

/*SVG ICONS COLOR*/
.icon-svg-path-color {
  fill: var(--text-dark);
}
/* TYPOGRAPHY RESPONSIVE */
@media (max-width: 767px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--primary);
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: left;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 27, 5px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--secondary);
  }

  h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
  }

  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22, 5px;
    letter-spacing: 0px;
    text-align: left;
  }

  body,
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18.75px;
    color: var(--text-dark);
  }

  .lead {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 19, 92px;
    letter-spacing: 0px;
    text-align: left;
  }

  .lead-bold {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-style: 700;
    font-weight: 400;
    line-height: 19, 92px;
    letter-spacing: 0px;
    text-align: left;
  }

  .base {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .base-bold {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18, 75px;
    letter-spacing: 0px;
    text-align: left;
  }

  .medium {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16, 41px;
    letter-spacing: 0px;
    text-align: left;
  }

  .medium-bold {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .small {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--text-dark);
  }

  .small-bold {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  .label {
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
  }

  .body2 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.15px;
  }

  button {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
  }

  .subtitle {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
  }

  .subtitle2 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: var(--text-dark);
  }

  .button-medium {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: var(--text-dark);
  }

  .caption,
  a {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: var(--text-dark);
  }

  .overline {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  ul {
    color: var(--text-dark) !important;
  }
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.desktop-600 {
  display: block;
}

.mobile-600 {
  display: none;
}

.view {
  padding-left: 272px;
  padding-top: 20px;
  padding-right: 20px;
  min-height: 100vh;
}
/* this is for the sections that can be rendered to auth and no auth users to show correctly */
.view-no-auth {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 120px;
  min-height: -webkit-calc(100vh - 144.75px);
  min-height: (100vh - 144.75px);
}

/*  style librery */

.passwordInfoPersonal {
  -webkit-text-security: disc;
}

.html2canvas-container {
  width: 1024px !important;
  height: 3000px !important;
}

.big-card-title {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-bottom: 0.35em;
}

.big-card-content {
  padding:15px
}

.syllabus-and-speakers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.MuiPaper-root.MuiDrawer-paper {
  background-color: var(--bg) !important;
  color: var(--text-dark);
}

#sideBar .MuiButtonBase-root.MuiListItem-root {
  padding-left: 5px !important;
}

#sideBar .MuiListItemIcon-root {
  min-width: 49px;
  justify-content: center;
}

#sideBar .MuiButtonBase-root.MuiListItem-root:hover {
  color: var(--primary) !important;
}

#sideBar .MuiButtonBase-root.MuiListItem-root:hover path {
  color: var(--primary) !important;
  fill: var(--primary) !important;
}

#sideBar .MuiSwitch-root .MuiSwitch-thumb {
  background-color: var(--primary);
}

#sideBar .MuiSwitch-root .MuiSwitch-track {
  background-color: var(--secondary-light);
}

.MuiPaper-root .MuiCard-root {
  background-color: var(--bg-dark) !important;
  border: none !important;
  box-sizing: border-box !important;
  border-radius: 24px !important;
}

#filters .MuiInputBase-root.MuiFilledInput-root::before,
.MuiInputBase-root.MuiFilledInput-root::before {
  border: none !important;
}

#filters .MuiSvgIcon-root {
  color: var(--secondary-light) !important;
}

.button-inscripcion {
  transition-duration: 0.5s;
  background: var(--secondary);
  border-radius: 70px;
  border-width: 0;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 11px 43px;
  color: var(--text-button);
  text-decoration: none;
  display: block;
  text-transform: none;
}

/* .button-inscripcion:hover {
  transition-duration: 0.5s;
  background: var(--secondary-dark);
  color: var(--text-button);
} */

.button-inscripcion-disabled {
  background-color: #999;
}

#inscription .MuiInputBase-root.MuiInput-root::before {
  border: none !important;
}

.MuiOptionUnstyled-root {
  color: var(--primary) !important;
}

#inscription .MuiInputBase-root.MuiInput-root,
#inscription input {
  color: var(--text-dark) !important;
  -webkit-text-fill-color: var(--text-dark) !important;
  background: var(--bg-dark) !important;
  border-radius: 10px;
  padding: 4px 4px;
}

#inscription .MuiFormLabel-root.MuiInputLabel-root {
  z-index: 1;
  left: 17px;
  top: 4px;
  color: var(--text-dark);
}

#inscription .MuiTabs-indicator {
  background: var(--primary-dark) !important;
}

#profile .MuiTabs-indicator {
  background: transparent;
}

#bar .MuiListItem-root {
  padding: 0 !important;
  width: auto !important;
}

#catalogue .MuiFilledInput-input {
  color: var(--text-dark) !important;
}

.MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .MuiButtonBase-root.MuiTab-root {
  background: white !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 11px 43px !important;
  color: var(--secondary-dark) !important;
  text-decoration: none !important;
  border: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width: 0px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.MuiButtonBase-root .MuiTab-root .MuiTab-textColorPrimary .Mui-selected .MuiButtonBase-root.MuiTab-root {
  background: white !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 11px 43px !important;
  color: var(--secondary-dark) !important;
  text-decoration: none !important;
  border: none !important;
}

#filters .MuiFilledInput-root ::before {
  border: none !important;
}


/*STYLES FOR SEARCH FILTERS*/
.course-search-filter{
  background-color: var(--bg);
  border-radius: 10px;
}

.course-search-filter div{
  border-radius: 10px;
}

.course-search-filter label{
  color: var(--text-dark);
  background-color: transparent;
}

.course-search-filter .course-search-filter-box {
  color: var(--text-dark);
}

.course-search-filter .course-search-filter-box:after {
  border-color: var(--secondary);
}

.course-search-filter .course-search-filter-label.Mui-focused {
color: var(--secondary)
}

.MuiFormControl-root filters .MuiFormControl-root {
  background-color: var(--bg);
  border-radius: 10px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9.5px 5px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  left: 285px !important;
}

.mercadopago-button {
  transition-duration: 0.5s;
  background: var(--secondary) !important;
  border-radius: 70px !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 11px 43px !important;
  color: var(--text-button) !important;
  width: 100%;
  text-decoration: none;
}

.mercadopago-button:hover {
  transition-duration: 0.5s;
  background: var(--secondary-dark);
  color: var(--text-button);
}

/*  style us */
#certificate-regular {
  padding: 51px;
  background: #fff;
}

#certificate-special {
  padding: 20px 40px;
  background: #fff;
}

.border-gradient {
  padding: 24px;
  width: 1024px;
  margin: auto;
  background: linear-gradient(141.77deg,
      rgba(127, 85, 211, 0.81) 15.67%,
      rgba(185, 161, 234, 0.7128) 36.98%,
      rgba(248, 141, 0, 0.6723) 92.3%);
}

.border-gradient-honorario {
  padding: 4px;
  border-radius: 24px;
  width: auto;
  margin: auto;
  background: linear-gradient(141.77deg,
      rgba(127, 85, 211, 0.81) 15.67%,
      rgba(185, 161, 234, 0.7128) 36.98%,
      rgba(248, 141, 0, 0.6723) 92.3%);
}




.border-100 {
  padding: 24px;
  width: 1024px;
  margin: auto;
  background: linear-gradient(180deg, #ccccbe 0%, #f8f8f0 49.48%, #b3b39f 100%);
}

.border-200 {
  padding: 24px;
  width: 1024px;
  margin: auto;
  background: linear-gradient(180deg, #facc48 0%, #ffe9b5 51.56%, #ecb541 100%);
}

.border-500 {
  padding: 24px;
  width: 1024px;
  margin: auto;
  background: linear-gradient(180deg, #92a2a9 0%, #d4e0e6 52.08%, #72848c 100%);
}

#register {
  display: flex;
}

#inscription .container-title-course {
  margin-top: 40px !important;
  margin-bottom: 45px !important;
}

.drawer {
  padding-left: 26.25px;
}

.containerProfileIcon {
  justify-content: center;
  align-items: center;
  display: grid;
}

.hoursTraining {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  width: "192px";
  /* Violeta/Light 2 */
  color: var(--text-especializaciones) !important;

  /* Shadow mini */
}

.title-card-course {
  margin-bottom: 0;
  margin-top: 15px;
  color: var(--secondary);
}

.divider-card {
  margin-bottom: 11px !important;
  border: 1px solid var(--bg-dark) !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.without-margin {
  margin: 0;
}

.notification-card {
  background-color: #eed1b1;
  width: 456px;
  box-shadow: 0px 18px 32px rgba(135, 134, 138, 0.12);
  border-radius: 16px;
  padding: 16px 19px;
  position: relative;
}

.organeRectangle {
  background: var(--primary);
  padding: 6px 7px;
  width: fit-content;
}

.card {
  background: var(--bg);
  box-sizing: border-box;
  border-radius: 24px;
  position: relative;
  min-height: 100px;
  overflow: hidden;
}

.transitionStep {
  filter: grayscale(1);
}

.transitionStep.accomplished {
  filter: grayscale(0);
}

.RSPBprogressBar {
  animation: 3s ease;
}

.card-line {
  background: var(--bg);
  box-sizing: border-box;
  border-radius: 24px;
  height: 100%;
  padding-top: 30px;
  padding-right: 25px;
  padding-left: 25px;
  margin-top: 20px;
}

.rectangleOrange {
  width: 100%;
  height: 120px;
  border-radius: 24px 24px 24px 0px;
  background: #f88d00;
}

.container-button-inscripcion {
  position: relative;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 40px;
}

.image-login {
  background: url("/src/static/images/Light\ .svg") no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.image-hero-home {
  padding-top: 120px;
  background: url("/src/static/images/homeHeroImage.png") no-repeat;
  background-size: cover;
  padding-bottom: 152px;
}

.noneMargin {
  margin: 0;
}

#login .MuiPaper-root {
  background-color: "#fefefec4";
}

#login .MuiTypography-root {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
}

#login .MuiTabs-indicator {
  background-color: transparent !important;
}

#login .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around;
}

#payment-form {
  position: absolute;
  top: 50%;
  left: 50%;
}

#login .MuiPaper-root.MuiPaper-elevation {
  background-color: var(--bg-login) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
  box-shadow: none !important;
  border-radius: 24px;
  padding-top: 15px;
}

#paper {
  background-color: transparent !important;
}

#login.paper-signUp {
  background-color: transparent !important;
}

#login .MuiPaper-root {
  box-shadow: none !important;
}

#login #back,
#inscription #back {
  color: var(--secondary) !important;
  background-color: transparent !important;
  text-transform: none;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
}

#login .MuiPaper-root {
  border-radius: 0xp 0px 4px 4px !important;
  background-color: transparent !important;
}

.MuiInputBase-root.MuiInput-root:before {
  border-bottom: none !important;
}

.MuiGrid-root>.MuiGrid-item {
  padding-top: 0% !important;
}
/*ESTILOS PARA TODOS LOS INPUT DE MATERIAL UI*/
.MuiInputBase-input.MuiInput-input {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 14px !important;
  text-decoration: none !important;
  color: var(--secondary-dark) !important;
}

.MuiInputBase-root.MuiInput-root {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-decoration: none !important;
  color: var(--secondary-dark) !important;
}

.MuiInputBase-root.MuiInput-root:after {
  border-bottom: none !important;
}

#login .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

#home {
  background: var(--bg);
  overflow-x: hidden;
}

#catalogue {
  overflow-x: hidden;
}

#home .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 10px;
  color: var(--secondary-dark) !important;
  background: var(--bg-dark) !important;
  border: 1px solid var(--secondary-light);
}

.css-480o17-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
}

.paperStyleLogin {
  min-height: 584px;
  height: auto;
  padding-left: 1%;
  margin: 0 40px;
  width: 100%;
  background-color: "rgba(254, 254, 254, 0.4)";
}

#btnStyle {
  padding-top: 130px;
}

.input-user {
  border-radius: 10px;
  width: 376px;
}

.input-pass {
  border-radius: 10px;
  margin-top: 26px;
  width: 376px;
}

.btn-edit {
  color: var(--secondary);
  position: relative;
  bottom: -5px;
  left: 597px;
}

.apodo {
  margin: 0px !important;
  color: var(--primary);
}

.icon-edit {
  position: absolute;
  bottom: 1px;
}

.person-desktop {
  display: flex;
}

.person-mobile {
  display: none;
}

.card-mobile-hs {
  display: none;
}

.background_1 {
  background-image: url("static/images/1er_fondo_cortado_home.svg") !important;
}

.button {
  background-color: var(--secondary);
  border-radius: 70px;
  color: var(--bg-dark) !important;
  margin: 20px;
}

.btnDekstopPrice {
  background-color: var(--secondary);
  border-radius: 70px;
  color: var(--bg-dark) !important;
  margin-right: 20px;
  margin-left: 141px;
  margin-bottom: 20px;
}

.cardCourse {
  min-width: 200px;
  width: 360px;
}

.cardCourseRecommended {
  min-width: 200px;
  max-width: 360px;
  border: 5px solid transparent;
  border-radius: 24px;
  background: linear-gradient(var(--bg), var(--bg-dark)) padding-box,
    /*this is your grey background*/
    linear-gradient(to bottom right,
      #7700ff,
      #FF6A36) border-box !important;
  border-radius: 29px !important;
  position: relative;
}

.container-card-content {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
}

.mt {
  margin-top: 16px;
  margin-left: 16px;
}

.styleMycourse {
  margin-bottom: 10px;
  margin-right: 30px;
}

#home .container-cards-services {
  background-color: var(--bg-dark-services);
  padding: 104px 76px;
  height: 400px;
}

#home .reviews-image {
  padding-top: 144px;
  padding-bottom: 240px !important;
  background: var(--bg-testimonials) no-repeat;
  background-size: cover;
}

#home .reviews-image-honorarios {
  padding-top: 144px;
  padding-bottom: 240px !important;
  background: url("/src/static/images/fondo-arriba.png") no-repeat;
  background-size: cover;
}

#home .container-contact {
  background: url("/src/static/images/homeHeroImage.png") no-repeat;
  background-size: cover;
  padding-bottom: 45px;
  background-position: 80%;
}

#home .charlemos {
  background: url("/src/static/images/contacto_home.svg") no-repeat;
  background-size: initial;
  background-position-y: 38%;
  background-position-x: 5%;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  margin: 30px 0px;
}

#showMore {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--bg-dark);
  height: 100%;
  padding-top: 100px !important;
  margin-top: 0px !important;
  overflow-y: scroll;
  height: auto;
}

.box-showMore {
  transition: all 0.3s ease-out;
  transform: translate(0);
}

.hidden-showMore {
  transition: all 1s ease-in;
  transform: translate(9999px);
}

.slick-track {
  margin: 0 !important;
}

textarea {
  border-radius: 10px !important;
  margin-top: 26px !important;
  height: 86px !important;
  background: var(--bg-dark) !important;
  color: var(--text) !important;
  border: 1px solid var(--secondary-light);
  padding: 10px;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
}

textarea:focus-visible {
  outline: none !important;
}

#home .MuiFormLabel-root.MuiInputLabel-root {
  background: var(--bg-dark);
}

#home .slick-track {
  display: flex !important;
}

#home .slick-slide {
  height: inherit !important;
  padding-bottom: 28px;
}

#home .slick-slide>div {
  height: 100% !important;
  display: flex;
  justify-content: center;
}

#home .cardCourseRecommended {
  max-width: none !important;
  padding-bottom: 62px;
  min-width: 290px;
}

#home .MuiCardActions-root {
  position: absolute;
  width: 90%;
  bottom: 0;
}

#home .MuiFormControl-root.MuiTextField-root {
  width: 95%;
}

.padding-inline {
  padding: 20px 10px 20px;
}

.link-header {
  color: var(--secondary);
}

.link-bottom {
  color: var(--primary);
}
/*ESTILOS PARA BOTON HAMBURGUESA - HAMBURGER BUTTON*/
.hamburger-menu {
  display: flex;
  align-items:center;
  justify-content: center;
  background-color: var(--secondary);
  padding: 15px;
  border: 0;
  border-radius: 50%;
  margin: 0px 15px
}

.hamburger-menu:hover {
  cursor: pointer;
}

/*HONORARIOS, ESPECIALIDADES Y SERVICIOS*/
.specialties-services-box {
  background-color: var(--bg-dark);
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
}
.specialties-services-box-mobile {
  min-width: 95%;
}

/*SWEET ALERT BACKDROP Z-INDEX CORRECTION*/
.swal2-container {
  z-index: 1300!important;
}

/*ESTILOS PARA QUE USETIFUL RESPETE EL MODO OSCURO*/
.uf-modal-inner, .uf-bubble {
  background-color: var(--bg)!important;
}
.uf-title {
  color: var(--primary)!important;
}
/*===============================================================
=================================================================
=================================================================
*/

/* RESPONSIVE */
@media (max-width: 500px) {
  .button-inscripcion {
    padding: 9px 27px;
    font-size: 12px !important;
    margin: auto;
    display: block;
  }

  .styleMycourse {
    margin-bottom: 0px;
    margin-right: 0px;
  }

  .mt {
    margin-top: 0px;
    margin-left: 16px;
  }

  .cardCourse {
    max-width: 100%;
    /* padding-top: 20px; */
  }

  .btnDekstop {
    display: none;
  }

  .sub-title-mobi {
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .title-Login-mob {
    font-family: "Montserrat", sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--primary);
  }

  .text-dark {
    font-size: 14px;
  }

  .icon-edit {
    position: absolute;
    bottom: 0px;
    top: 5px;
    right: 191px;
  }

  .btn-edit {
    display: none;
  }

  .topBar {
    padding-bottom: 0px;
    padding-top: 16px;
    background: var(--bg-dark);
    width: 59%;
    padding-right: 0px;
  }

  .person-desktop {
    display: none;
  }

  .card-dekstop-hs {
    display: none;
  }

  .css-1yvr6ud-MuiGrid-root {
    flex-basis: 0px !important;
    max-width: 100% !important;
  }
}

/* --------------------- */
.button-step-white {
  transition-duration: 0.5s;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 11px 43px;
  color: var(--secondary-dark);
  text-decoration: none;
  border: none;
  width: 264px;
  height: 34px;
  text-align: center !important;
}

.button-step-white:active {
  border: 2px solid var(--secondary-light);
  border-color: #b889ee !important;
}

#button-step {
  transition-duration: 0.5s;
  background: var(--bg-dark);
  border-radius: 10px;
  border-width: 0;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 11px 43px;
  color: var(--text-dark);
  text-decoration: none;
  text-transform: none;
  border: none;
}

#button-step:active {
  font-weight: 700;
  border: 2px solid var(--secondary);
}

#button-step-active {
  transition-duration: 0.5s;
  background: var(--bg-dark);
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 11px 43px;
  color: var(--text-dark);
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
  border: 2px solid var(--secondary);

}


.selectClass {
  background-color: var(--bg-step) !important;
  color: var(--text) !important;
}

#inscription .paperStyle {
  width: 456px;
  height: 584px;
}

#btnStyle {
  padding-top: 130px;
  text-align: center;
}

.loginView {
  display: flex;
  justify-content: center;
}

#svg-registry {
  position: absolute;
  bottom: 115px;
  margin: auto;
  left: 0px;
  right: 232px;
}

.topBarHome {
  width: 100%;
  left: 0;
  right: 0;
  background-color: var(--bg-topbar) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
}

.contactShared {
  width: -moz-fit-content;
  width: fit-content;
  position: sticky !important;
  border-radius: 45px !important;
  padding: 16px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  -webkit-backdrop-filter: saturate(180%) blur(10px) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
  margin-right: 0;
  position: -webkit-sticky;
  height: 0px;
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
  bottom: 20px;
}

.contactShareSoon {
  display: flex;
  align-items: center;
  position: absolute;
  margin: auto;
  width: fit-content;
  right: -10%;
  bottom: 41px !important;
  border-radius: 45px !important;
  padding: 16px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  -webkit-backdrop-filter: saturate(180%) blur(10px) !important;
  backdrop-filter: saturate(180%) blur(10px) !important;
  margin-right: 0;
  position: -webkit-sticky;
  right: 5%;
  margin-bottom: 69px;
}

#home .makeStyles-button-13 {
  background-color: transparent !important;
}

#home .makeStyles-root-1 {
  overflow-y: inherit;
  overflow-x: hidden;
}

#home .makeStyles-item-2 {
  padding: 23px 0px;
}

#card-review {
  height: 100%;
  position: relative;
  border-radius: 24px;
  background-color: var(--bg-dark);
  padding: 24px 32px;
}

#home .makeStyles-indicators-4 {
  z-index: 10000;
  position: relative;
}

.AppSlick {
  width: 100vw;
  margin-top: 16px;
  margin-bottom: 52px;
}

.slick-list {
  padding: 0 !important;
}

.slick-slide {
  padding-right: 8px;
  padding-left: 5px;
}

.slick-dots li button::before {
  color: #f8bf7f !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
  color: transparent;
  height: 8px;
  width: 24px;
  border-radius: 20px;
  background: transparent;
  display: none;
}

.slick-dots li.slick-active button {
  opacity: 0.75;
  color: var(--secondary);
  height: 8px;
  width: 24px;
  border-radius: 20px;
  background: var(--secondary);
}

.slick-dots {
  bottom: -38px !important;
}

.card .card-actions-mui {
  display: grid;
  text-align: center;
  padding-bottom: 19px;
  padding-top: 20;
  margin-inline: 24px;
}

.bottom-bar {
  background: var(--bg-dark-services);
}

@media (max-width: 1024px) {

  #home .container-cards-services {
    height: 100%;
  }

  .view {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  #home .cardCourseRecommended {
    padding-bottom: 0;
  }

  .desktop-600 {
    display: none;
  }

  .mobile-600 {
    display: block;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    left: 47px !important;
  }

  .css-1yvr6ud-MuiGrid-root {
    max-width: 100% !important;
  }
}

@media (max-width: 500px) {
  .title-card-course {
    margin-top: 0;
  }

  .card .card-actions-mui {
    margin-inline: 10px;
  }

  .container-login {
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
  }

  #login .MuiPaper-root {
    margin-bottom: 20px;
  }

  #login .MuiPaper-root {
    width: 90%;
  }

  #login #btnLogin {
    width: 100% !important;
  }

  #login #btnRecuperar {
    width: 100% !important;
  }

  .paperStyleLogin {
    width: 90%;
  }

  .button-step {
    padding: 11px 23px;
  }

  .container-login {
    max-height: 675px;
  }

  #button-step,
  #button-step-active {
    width: 40%;
  }

  #login #btnRecuperar {
    width: 70% !important;
    bottom: 26px !important;
  }

  #btnStyle {
    padding-top: 56px;
  }

  .pass {
    margin-left: 0px;
  }

  .container-button-inscripcion {
    position: absolute;
    bottom: 40px;
    margin: auto;
    left: 0px;
    right: -23px;
  }

  #btn-registry {
    position: absolute;
    bottom: 98px;
    margin: auto;
    right: 137px;
  }
}

@media (max-width: 400px) {
  .AppSlick {
    width: 124vw;
  }

  .slick-dots {
    left: -14%;
  }

  .cards-container {
    width: 100%;
    flex-direction: column;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    left: 29px !important;
  }

  #home .container-cards-services {
    padding: 104px 8px !important;
  }
}

@media (max-width: 280px) {
  #btnStyle {
    padding-top: 90px;
  }

  #login .MuiPaper-root {
    margin-bottom: 44px;
  }
}