.topBar {
    padding-bottom: 16px;
    padding-top: 16px;
    background-color: var(--bg-topbar);
    width: 100%;
    padding-right: 40px;
    box-shadow: none !important;
  }
  
  #topbar-content {
    width: 100%;
}

@media (min-width: 900px) {
  .mobile-hamburguer-button {
    display: none;
  }
}