.loginView input {
    background-color: var(--bg-dark);
    color: var(--text-dark);
}

.loginView label {
    color: var(--text-dark)
}
.login-signup-input-fields {
    background-color: transparent;
}

.login-signup-input-fields div input{
    border-radius: 10px!important;
}

.btnLogin {
    background-color: var(--secondary);
    width: 376px !important;
    height: 48px !important;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    text-transform: none;
    border-width: 0;
}

.registration-button-container{
    width: 100%;
    display:flex;
    justify-content: center;
}


#login {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content:space-around;
    margin-top: 50px;
}

.container-login {
    margin: 20px;
    width: 100%;
    min-width: 400px;
}

.title-login-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

}

.title-login {
    text-align: center;
}

@media (max-width: 900px) {
    #login {
        flex-direction: column;
        justify-content: center;
    }
    .container-login {
        margin: 20px;
        width: 80%;
    }
}

@media (max-width: 400px) {
    .container-login {
        margin: 20px;
        width: 100%;
        min-width: 10px;
    }
}

#login-form {
    width: 85%;
    min-height: 500px;
}


#login .MuiOutlinedInput-notchedOutline {
    border: none;
    width: 376px;
}

#login .MuiFormLabel-root.MuiInputLabel-root {
    font-family: "Roboto", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration: none !important;

}

#login .mui-step-form {
    width: 456;
    height: 584;
}

#login .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation20 {
    width: 456;
    height: 584;
}


#login .MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}