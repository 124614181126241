.card-actions {
    padding: 5px 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.card-fees {
    display: flex;
    flex-direction: row;
}

.card-fee{
    font-size:16px;
    margin: 0;
}

.ars-fee, .usd-fee {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ars-fee {
    margin-left: 10px;
}

@media (max-width: 500px) {

    .card-actions {
        padding: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
    }
    .card-prices {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
