#my-course-small-card-details {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 30px;
}

#my-course-sessions-material, #my-course-certificate-specialty {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
@media (max-width: 600px) {
    #my-course-sessions-material, #my-course-certificate-specialty {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}

.course-data-card {
    min-width: 275px;
    padding: 20px;
}

@media (max-width: 320px) {
    .course-data-card {
        min-width: 80%;
    }
}


.course-data-container {
    margin: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.course-data-title-icon-container {
    margin: 0 0 14px 0;
    display : flex;
    flex-direction: row;
}

.course-data-title{
    margin: 0 0 0 10px;
    display: inline-block;
}

.session-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.mini-button {
    margin: 0px 10px;
    padding:10px;
    background-color: var(--secondary);
    border-radius: 20px;
    text-align: center;
}

.session-item {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.session-dropdown {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--bg);
    width: 100%;
    font-size: 16px;
    line-height:40px;
    color: var(--text-dark);
}

#course-sessions, #course-specialty-progress {
    flex-grow: 2;
}

#course-material, #course-certificate {
    flex-grow: 1;
}