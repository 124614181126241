.registration-form-and-course-info-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: stretch;
    margin-bottom: 30px
}

.course-registration-form {
    flex-basis: 66%;
}

.course-registration-info {
    flex-basis: 34%;
}