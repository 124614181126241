.course-card {
    min-width: 320px;
    max-width: 450px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

.card-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.card-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-bottom-right-radius: 24px;
    width: 25%;
}

.card-image {
    height: 60px;       
}

.card-title-container {
    margin: 0px;
    width: 75%;
    padding: 7px;
}

.card-title {
    margin: 0;
    color: var(--secondary);
}


.card-speaker {
    text-align: center!important;
}

.card-speaker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-schedule {
    background-color: var(--bg-dark);
    margin: 0 5px!important;
    padding: 5px;
    border-radius: 10px;
    max-width: 80px;
}

.card-sessions-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-content {
    margin-bottom: 10px;
    padding: 0 10px;
    width: 100%;
}

.card-number-of-sessions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.past-course-availability-notice {
    color: var(--primary);
    font-size: 12px;
    text-align: center;
}

.card-course-recommended {
    border: 5px solid transparent;
    border-radius: 24px;
    background: linear-gradient(var(--bg), var(--bg-dark)) padding-box,
    linear-gradient(to bottom right,#7700ff,#FF6A36) border-box !important;
    border-radius: 29px !important;
    position: relative;
}

@media (max-width: 500px) {
    .course-card {
        width: 100%;
    }

    .card-image-container {
        width: 30%;
    }
}

@media (max-width: 360px) {
    .course-card {
       width: 100%;
       min-width: 0px;
    }
}

@media (max-width: 270px) {
    .card-image {
        height: 40px;
    }
}