#certificate-card-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 2;
    gap: 10px;
}

#certificate-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 250px;
    flex-grow: 1;
    padding: 20px;
    flex: 1;
}

#certificate-card-header{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}