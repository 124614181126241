.course-detail-box {
    background: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header-image { 
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.schedules {
    background-color: var(--bg-dark);
    margin: 5px!important;
    border-radius: 10px;
}

.fee-box {
    background-color: var(--bg-dark);
    padding: 10px;
    border-radius: 15px;
    min-width: 45%;
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prices {
    display: flex;
    justify-content: space-evenly;
}

.currency {
    margin:0;
}

.course-detail-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 10px;
}