.dropdown-list-item {
background-color: var(--bg-dark);
}

/*Para eliminar la barra blanca que genera Material UI arriba de la lista de opciones*/
#country-select-demo-listbox, #provincias-select-listbox {
    padding: 0;
}

.button-and-error-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
}

.form-container {
    padding: 20px
}