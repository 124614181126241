
/*CSS FOR SPINNER */
.loadingSpinner {
    display: flex;
    position: relative;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
  .loadingSpinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loadingSpinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .loadingSpinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loadingSpinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loadingSpinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /*BUTTON STYLES*/

  .animated-button {
    -webkit-border-radius: 50;
    -moz-border-radius: 50;
    border-radius: 50px;
    font-family: Arial;
    font-size: 20px;
    min-height: 40px;
    padding: 5px 30px 5px 30px;
    text-decoration: none;
    text-align: center;
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    }

    .animated-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 10px;
    }
    .save-button-text {
      margin: 0;
    }
  .save-button-success {
    background: #a8ff78; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #a8ff78, #78ffd6); 
    background: linear-gradient(to right, #a8ff78, #78ffd6);
  }
  .save-button-loading {
    background: #757F9A; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #757F9A, #D7DDE8);
    background: linear-gradient(to right, #757F9A, #D7DDE8);
    padding: 5px 30px 5px 30px;
  }