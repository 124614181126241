#currency-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 10px;
}

#currency-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

#currency-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

#currency-name {
    align-content: center;
    text-align: center;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

#payment-button-deactivated, #payment-button-loading {
    background-color: #e0e0e0!important;
    background: #e0e0e0!important;
    color: #bdbdbd!important;
    width: 100%;
    margin-left: 0px;
}

#payment-button-loading {
    padding: 11px;
    border-radius: 70px;
    color: var(--text-button)!important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}