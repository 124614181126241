.sideBar {
    color: var(--text-dark);
}

#sideBar{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-logo {
    margin: 20px 10px;
}

.sideBar-menu {
    margin-top: 30px;
}

.sideBarButtons {
    padding: 5px;
    margin:10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    background: transparent;
    border: 0;
    align-items: center;
}

.sideBarButtons:hover .sideBarButtonText {
    color: var(--primary) !important;
    cursor: pointer;
}

.sideBarButtons:hover .icon-svg-path-color {
    fill: var(--primary) !important;
}

.sideBarIcons {
    padding: 0px 10px;
}

.sideBarButtonText {
    margin: 0px
}

.top-bar-dashboard-container {
    width: 100%;
    height: 100%;
}
/*Done with media query so that the topbar will not be visible when the sidebar is visible*/
@media (max-width: 1024px) {
    .top-bar-dashboard {
        display: flex;
        justify-content:space-between;
        align-items: center;
        background-color: var(--bg-topbar) !important;
        backdrop-filter: saturate(180%) blur(10px) !important;
        position: fixed;
        top: 0;
        z-index: 100;
        width: 100%;
        padding: 0px 0px 0px 20px;
        height: 80px;
    }
  }


.menu-button-group-title {
background-color: var(--bg-dark);
display: inline-block;
padding: 2px 10px;
margin-bottom: 0px;
margin-left: 10px;
border-radius: 10px;
}